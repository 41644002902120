<template>
  <div>
    <el-row type="flex" :gutter="20">
      <el-col>
        <el-row type="flex" >
          <el-input placeholder="请输入" type="number" v-model="ipt1"
            oninput="value=value.replace(/[^0-9.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"
            ><template slot="append">亿</template></el-input
          >
        </el-row>
      </el-col>
   
      <el-col>
        <el-row type="flex" >
          <el-input placeholder="请输入" type="number"  v-model="ipt2"
            oninput="value=value.replace(/[^0-9.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"
            ><template slot="append">万</template></el-input
          >
        </el-row>
      </el-col>
     
      <el-col>
        <el-row type="flex" >
          <!-- <el-input placeholder="请输入" type="number" v-model="ipt3" oninput="value=value.replace( /^(\d{0,4})(\.(\d{0,2}))?$/g)"  :disabled="checked"><template slot="append">元</template></el-input> -->
          <el-input
            placeholder="请输入"
            type="number"
            oninput="value=value.replace(/[^0-9.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"
            v-model="ipt3"
            ><template slot="append">元</template></el-input
          >
        </el-row>
      </el-col>
      {{ ipt }}
    </el-row>
  </div>
</template>

<script>
export default {
  props: ["sum", "width"],
  data() {
    return {
      ipt1: "",
      ipt2: "",
      ipt3: "",
    };
  },
  computed: {
    ipt() {
      // console.log(Number(this.ipt1) * 100000000)
      // console.log(this.ipt2)
      // console.log(this.ipt3)
      // if (this.ipt1 || this.ipt2 || this.ipt3) {
      const value = Number(this.ipt1) * 100000000 + Number(this.ipt2) * 10000 + Number(this.ipt3)
      // console.log(value)
      // console.log(Math.floor(Number(value) * 100) / 100)
      this.$emit("getValue", Math.floor(Number(value) * 100) / 100)
      // return
      // }
      // return ''
    },
  },
  watch: {
    // ipt1(newValue, oldValue) {
    //   const value = Number(newValue) * 100000000 + Number(this.ipt2) * 10000 + Number(this.ipt3)
    //   this.$emit('getValue', Math.floor(Number(value) * 100) / 100)
    // },
    // ipt2(newValue, oldValue) {
    //   const value = Number(this.ipt1) * 100000000 + Number(newValue) * 10000 + Number(this.ipt3)
    //   this.$emit('getValue', Math.floor(Number(value) * 100) / 100)
    // },
    // ipt3(newValue, oldValue) {
    //   const value = Number(this.ipt1) * 100000000 + Number(this.ipt2) * 10000 + Number(newValue)
    //   this.$emit('getValue', Math.floor(Number(value) * 100) / 100)
    // }
    sum(newValue, oldValue) {
      if (newValue) {
        this.ipt1 = this.bigNumberTransform(newValue).y || "";
        this.ipt2 = this.bigNumberTransform(newValue).w || "";
        this.ipt3 = this.bigNumberTransform(newValue).o || "";
      }
    },
  },
  created() {
    if (this.sum) {
      this.ipt1 = this.bigNumberTransform(this.sum).y || "";
      this.ipt2 = this.bigNumberTransform(this.sum).w || "";
      this.ipt3 = this.bigNumberTransform(this.sum).o || "";
    }
  },
  methods: {
    bigNumberTransform(value) {
      const y = Math.floor(value / 10000 / 10000);
      const w = Math.floor((value / 10000) % 10000);
      const o = Math.floor(Number(value % 10000) * 100) / 100;
      return { y, w, o };
    },
  },
};
</script>

<style lang="less" scoped>
</style>
